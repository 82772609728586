<template>
  <div>
    <form-title :text="$t('title.select_time')" />
    <form-content>
      <div>
        <div class="mb-4">
          <div class="flex justify-center items-center -mx-2 mb-12">
            <div class="px-2">
              <div class="h-64 overflow-auto relative custom-scroll-bar px-2">
                <label
                  class="cursor-pointer flex items-center justify-center rounded border bg-white border-gray-300 text-xl  hover:border-red-500 hover:text-white hover:bg-red-500  focus:border-red-700 focus:bg-red-700 h-16 px-8 mb-2 w-full font-headings transition-colors "
                  v-for="hour in hours"
                  :key="'hour-' + hour"
                  :id="'label-hour-' + hour"
                  :class="
                    hour === timeHour
                      ? 'bg-red-500 border-red-500 text-white bg-opacity-100'
                      : ''
                  "
                >
                  {{ hour | formatTime }}
                  <input
                    type="radio"
                    name="hour"
                    :id="'hour-' + hour"
                    :value="hour"
                    v-model="timeHour"
                    class="h-0 w-0 opacity-0 absolute"
                  />
                </label>
              </div>
            </div>
            <div class="px-2">:</div>
            <div class="px-2">
              <div class="h-64 overflow-auto relative custom-scroll-bar px-2">
                <label
                  class="cursor-pointer flex items-center justify-center rounded border bg-white border-gray-300 text-xl  hover:border-red-500 hover:text-white hover:bg-red-500  focus:border-red-700 focus:bg-red-700 h-16 px-8 mb-2 w-full font-headings transition-colors "
                  v-for="minute in minutes"
                  :key="'min-' + minute"
                  :id="'label-min-' + minute"
                  :class="
                    minute === timeMinute
                      ? 'bg-red-500 border-red-500 text-white bg-opacity-100'
                      : ''
                  "
                >
                  {{ minute | formatTime }}
                  <input
                    type="radio"
                    name="minutes"
                    :id="'min-' + minute"
                    :value="minute"
                    v-model="timeMinute"
                    class="h-0 w-0 opacity-0 absolute"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center flex flex-col mt-4 mb-12">
          <button
            @click="onSubmit(timeGenerated)"
            class="btn bg-red-500 hover:bg-red-600 focus:bg-red-700 text-white  sm:max-w-xs w-full mx-auto"
            v-text="$t('button.continue')"
          />
        </div>
      </div>
    </form-content>
  </div>
</template>

<script>
import FormTitle from "./FormTitle.vue";
import FormContent from "./FormContent.vue";

export default {
  name: "SelectTime",
  components: {
    FormTitle,
    FormContent,
  },

  created() {
    this.timeHour = this.timeSaved ? parseInt(this.timeSaved.split(":")[0]) : 0;
    this.timeMinute = this.timeSaved
      ? parseInt(this.timeSaved.split(":")[1])
      : 0;
  },

  mounted() {
    this.scrollIn(`label-hour-${this.timeHour}`);
    this.scrollIn(`label-min-${this.timeMinute}`);
  },

  data() {
    return {
      timeHour: 0,
      timeMinute: 0,
      hours: [...Array(24).keys()],
      minutes: [...Array(60).keys()],
      scrollDelay: null,
    };
  },

  methods: {
    async onSubmit(payload) {
      this.$emit("update-user-data", { birthTime: payload });
    },
    scrollIn(el, behaviour = "auto") {
      document.getElementById(el).scrollIntoView({
        block: "center",
        inline: "nearest",
        behavior: behaviour,
      });
    },
  },

  computed: {
    timeSaved() {
      return this.$store.state.userData.birthTime;
    },
    timeGenerated() {
      let h = String(this.timeHour).padStart(2, "0");
      let m = String(this.timeMinute).padStart(2, "0");
      return `${h}:${m}`;
    },
  },

  filters: {
    formatTime(value) {
      return String(value).padStart(2, "0");
    },
  },

  watch: {
    timeHour(newValue, oldValue) {
      newValue !== oldValue &&
        this.scrollIn(`label-hour-${newValue}`, "smooth");
    },
    timeMinute(newValue, oldValue) {
      newValue !== oldValue && this.scrollIn(`label-min-${newValue}`, "smooth");
    },
  },
};
</script>
